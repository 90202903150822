export const BEFORE_LOGIN_NAV = [
    {
        type: "link",
        link: "/#about",
        name: "About Us"
    },
    {
        type: "dropdown",
        id: 'categories',
        name: "Categories",
        items: [
            {
                type: "link",
                link: "/#sports",
                name: "Sports",
                eventKey:"sports"
            },
            {
                type: "link",
                link: "/#collectible",
                name: "Collectible",
                eventKey:"collectibles"
            },
            {
                type: "link",
                link: '/#entertainment',
                name: "Entertainment",
                eventKey:"entertainment"
            },
        ]
    },
    {
        type:"link",
        link:"https://dev-nft-mintpad.vlinder.io/",
        name:"Mintpad"
    },
    {
        type: "link",
        link: "/#contact",
        name: "Contact"
    },
    
    {
        type:"avatar",
        link:"/login",
        avatar:"https://i.ibb.co/dmQzyHR/wallet-blue.png"
    }
  
];

export const AFTER_LOGIN_NAV = [
    {
        type: "link",
        link: "/#about",
        name: "About Us"
    },
    {
        type: "dropdown",
        id: 'categories',
        name: "Categories",
        items: [
            {
                type: "link",
                link: "/#sports",
                name: "Sports",
                eventKey:"sports"
            },
            {
                type: "link",
                link: "/#collectible",
                name: "Collectible",
                eventKey:"collectibles"
            },
            {
                type: "link",
                link: '/#entertainment',
                name: "Entertainment",
                eventKey:"entertainment"
            },
        ]
    },
    {
        type:"link",
        link:"https://dev-nft-mintpad.vlinder.io/",
        name:"Mintpad"
    },
    {
        type: "link",
        link: "/#contact",
        name: "Contact"
    },
    {
        type:"avatarDropdown",
        name:"Mahendra C",
        avatar:"https://ui-avatars.com/api/?name=Mahendra+C",
        items: [
            {
                type: "link",
                link: "/my-wallet",
                name: "My Wallet",
                eventKey: "wallet",
            },
            {
                type: "link",
                link: "/my-profile",
                name: "My Profile",
                eventKey: "profile",
            },
            {
                type: "link",
                link: "/my-transactions",
                name: "My Transaction",
                eventKey: "transaction",
            },
            {
                type: "link",
                link: "/logout",
                name: "Logout",
                eventKey: "logout",
            }
        ]
    }
  
];

export const INNER_LOGIN_NAV = [
    {
        type: "link",
        link: "/#about",
        name: "About Us"
    },
    {
        type: "dropdown",
        id: 'categories',
        name: "Categories",
        items: [
            {
                type: "link",
                link: "/sports",
                name: "Sports",
                eventKey:"sports"
            },
            {
                type: "link",
                link: "/collectibles",
                name: "Collectible",
                eventKey:"collectibles"
            },
            {
                type: "link",
                link: '/entertainment',
                name: "Entertainment",
                eventKey:"entertainment"
            },
        ]
    },
    {
        type:"link",
        link:"https://dev-nft-mintpad.vlinder.io/",
        name:"Mintpad"
    },
    {
        type: "link",
        link: "/#contact",
        name: "Contact"
    },
    {
        type:"avatarDropdown",
        name:"Mahendra C",
        avatar:"https://ui-avatars.com/api/?name=Mahendra+C",
        items: [
            {
                type: "link",
                link: "/my-wallet",
                name: "My Wallet",
                eventKey: "wallet",
            },
            {
                type: "link",
                link: "/my-profile",
                name: "My Profile",
                eventKey: "profile",
            },
            {
                type: "link",
                link: "/my-transactions",
                name: "My Transaction",
                eventKey: "transaction",
            },
            {
                type: "link",
                link: "/logout",
                name: "Logout",
                eventKey: "logout",
            }
        ]
    }
];

export const INNER_LOGOUT_NAV = [
    {
        type: "link",
        link: "/#about",
        name: "About Us"
    },
    {
        type: "dropdown",
        id: 'categories',
        name: "Categories",
        items: [
            {
                type: "link",
                link: "/sports",
                name: "Sports",
                eventKey:"sports"
            },
            {
                type: "link",
                link: "/collectibles",
                name: "Collectible",
                eventKey:"collectibles"
            },
            {
                type: "link",
                link: '/entertainment',
                name: "Entertainment",
                eventKey:"entertainment"
            },
        ]
    },
    {
        type:"link",
        link:"https://dev-nft-mintpad.vlinder.io/",
        name:"Mintpad"
    },
    {
        type: "link",
        link: "/#contact",
        name: "Contact"
    },
    {
        type:"avatar",
        link:"/login",
        avatar:"https://i.ibb.co/dmQzyHR/wallet-blue.png"
    }
]