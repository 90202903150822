import gql from "graphql-tag";

export const GET_ASSET_DETAIL = gql`
query Query($tokenId: String!, $storeId: MID) {
    getAsset(tokenId: $tokenId, storeId: $storeId) {
      title
    subtitle
    serialNo
    category
    price {
      type
      amount
      currency
      sale {
        salePrice
        saleEndDate
      }
      symbol
    }
    symbol
    attrs {
      name
      val
      disp_type
      disp_val
    }
    media {
      type
      src
      thumbnail
    }
    timeline {
      event
      date
      from {
        value
        link
      }
      to {
        value
        link
      }
      proof {
        value
        link
      }
    }
    }
  }
`
